import { get } from "lodash";
import { API } from "../../../utils/constants";
import { Badge } from "../badge";
import {
  ResultItemWrapper,
  Text,
  Image,
  ImageContainer,
} from "./ResultItem.style";
import { IndexTypeMapping } from "../../../utils/search";

export const ResultItem = ({ item }) => {
  const {
    id,
    title,
    thumbnail,
    path,
    primaryColor,
    borderRadius,
    setSelected,
    selected,
    categories,
    index
  } = item;
  const categoryTitle = get(categories, "[0].title");
  const typeContent = index;
  console.log("🚀 ~ ResultItem ~ typeContent:", index)
  return (
    <ResultItemWrapper
      key={id}
      color={primaryColor}
      preventScrollReset={true}
      to={`${path}${id}`}
      onMouseOver={() => setSelected(id)}
      onMouseOut={() => setSelected(null)}
    >
      <ImageContainer
        selected={selected === id}
        borderRadius={`${borderRadius}px`}
      >
        <Image key={id} src={`${API.URL}${thumbnail}`} />
      </ImageContainer>
      <Text>{title}</Text>
      <span>
      {typeContent && <Badge>{get(IndexTypeMapping, `${typeContent}.label`)}</Badge>}
      {categoryTitle && <Badge>{categoryTitle}</Badge>}
      </span>
    </ResultItemWrapper>
  );
};
