import {mapImageFormats} from "../../../utils/mappings";
import {EVENTS_PAGE} from "../../../utils/constants";

const STATE = {}

const mapEventsPageData = (payload) => {
    if (!payload) return {}
    const {data} = payload;
    if (!data) return {}
    const item = data.shift();
    if (!item) return {}

    const {id, attributes: {title, subtitle, image, links, background_image, back_button_color}} = item;
    return {
        id,
        title,
        subtitle,
        image: image.data ? mapImageFormats(image) : null,
        links,
        backgroundImage: background_image.data ? mapImageFormats(background_image) : null,
        backButtonColor: back_button_color
    }
}

const events = (state = STATE, action) => {
    const {type, payload} = action;
    switch (type) {
        case EVENTS_PAGE.LOAD:
            return {};
        case EVENTS_PAGE.LOAD_ERROR:
            return {};
        case EVENTS_PAGE.LOAD_SUCCESS:
            return {...mapEventsPageData(payload)};
        default:
            return state;
    }
}

export default events;
