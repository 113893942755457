import {STICKER} from "../../../utils/constants";

const STATE = {}

const mapStickerData = (payload) => {
    const {data} = payload;
    if (data && data.length > 0) {
        const {attributes, id} = [...data].shift();
        const { hyperlink, publishedAt } = attributes;
        const sticker = attributes?.sticker?.data?.attributes;
        return {id, hyperlink, publishedAt, sticker};
    }
    return {};
}

const sticker = (state = STATE, action) => {
    const {type, payload} = action;
    switch (type) {
        case STICKER.LOAD:
            return {};
        case STICKER.LOAD_ERROR:
            return {};
        case STICKER.LOAD_SUCCESS:
            return {...mapStickerData(payload)};
        default:
            return state;
    }
}

export default sticker;
