import {getLatestContentVersion, mapImageFormats} from "../../../utils/mappings";
import {MEDIA_HUB} from '../../../utils/constants';

const STATE = {
    items: null,
    initialSectionId: null,
    initialItemId: null,
    content: null,
    item: null,
}

const mapMediaHub = (payload) => {
    const {data: {attributes: {media_hub_categories}}} = payload;
    return mapNavChildren(media_hub_categories);
}

const mapNavChildren = (payload, secondary = false) => {
    const {data} = payload;
    return data.map(item => {
        const {id, attributes: {title, video, children}} = item;
        if (video) {
            const {
                videoFile: {data: {attributes: {url: videoUrl, caption: videoCaption}}},
                coverImage: {data: {attributes: {formats: {large: {url: coverUrl}}}}}
            } = video;
            return secondary ? {
                id: `${id}`,
                title,
                video: videoUrl,
                cover: coverUrl,
                caption: videoCaption
            } : {id: `${id}`, title, children: mapNavChildren(children, true)};
        }
        return secondary ? {id: `${id}`, title, video} : {id: `${id}`, title, children: mapNavChildren(children, true)};
    })
}

const mapItems = (payload) => {
    const {data} = payload;
    return data?.attributes?.items?.data?.map(item => {
        const {id, update, attributes: {title, description, locked, elements, image}} = getLatestContentVersion(item);
        return {
            id: `${id}`,
            title: `${title}${update ? ' *' : ''}`,
            description,
            image: mapImageFormats(image),
            locked,
            elements: mapElements(elements)
        }
    })
}

const mapElements = (elements) => {
    return elements.map(element => {
        const {__component} = element;
        switch (__component) {
            case 'elements.external-link':
                const {title, url} = element;
                return {
                    type: 'link',
                    title,
                    url,
                }
            case 'elements.video':
                const coverUrl = element.coverImage?.data?.attributes?.url;
                const videoUrl = element.videoFile?.data?.attributes?.url;
                const videoCaption = element.videoFile?.data?.attributes?.caption;
                return {
                    type: 'video',
                    url: videoUrl,
                    cover: coverUrl,
                    caption: videoCaption,
                }
            default:
                return element;
        }
    })
}

const mapItem = (payload) => {
    const {data} = payload;
    const {update, attributes: {title, description, locked, image, elements}} = getLatestContentVersion(data);
    return {
        title: `${title}${update ? ' *' : ''}`,
        description,
        image: mapImageFormats(image),
        locked,
        elements: mapElements(elements)
    }
}

function mediaHub(state = STATE, action) {
    const {type, payload} = action;
    switch (type) {
        case MEDIA_HUB.RESET_NAV:
            return {...state, items: null, initialSectionId: null, initialItemId: null, content: null};
        case MEDIA_HUB.LOAD_NAV:
            return {...state, items: null, initialSectionId: null, initialItemId: null, content: null};
        case MEDIA_HUB.LOAD_NAV_SUCCESS:
            const categories = mapMediaHub(payload);
            if (categories.length) {
                for (const category of categories) {
                    const {id: initialSectionId, children} = category;
                    if (children.length) {
                        const {id: initialItemId} = children[0];
                        return {...state, items: categories, initialSectionId, initialItemId};
                    } else {
                        return {...state, items: categories, initialSectionId, initialItemId: categories[0]?.id};
                    }
                }
                return {...state};
            }
            return {...state};
        case MEDIA_HUB.LOAD_CONTENT:
            return {...state, content: null}
        case MEDIA_HUB.LOAD_CONTENT_SUCCESS:
            const items = mapItems(payload);
            return {...state, content: items};
        case MEDIA_HUB.LOAD_ITEM:
            return {...state, item: null}
        case MEDIA_HUB.LOAD_ITEM_SUCCESS:
            const item = mapItem(payload);
            return {...state, item};
        default:
            return state;
    }
}

export default mediaHub;
