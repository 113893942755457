import { assign, cloneDeep, forEach, get, map } from "lodash";
import { API } from "../constants";

export const transformData = (payload) => {
  const { attributes } = payload?.data;
  const main_logo = API?.URL + get(attributes, "main_logo.data.attributes.url");
  const favicon = API?.URL + get(attributes, "favicon.data.attributes.url");
  const footer_left_logo =
    API?.URL + get(attributes, "footer_left_logo.data.attributes.url");
  const footer_right_logo =
    API?.URL + get(attributes, "footer_right_logo.data.attributes.url");
  const background_image =
    API?.URL + get(attributes, "background_image.data.attributes.url");
  return {
    ...attributes,
    main_logo,
    auth_panel_header: get(attributes, "auth_panel_header", []),
    auth_panel_footer: get(attributes, "auth_panel_footer", []),
    footer_text: get(attributes, "footer_text", []),
    sidebar_footer: get(attributes, "sidebar_footer", []),
    favicon,
    footer_left_logo,
    footer_right_logo,
    background_image,
  };
};

export const replaceNewsItemMenu = (menuItems, newLabel) => {
  let modifiedData = map(menuItems, (item) => {
    let newItem = cloneDeep(item);
    if (newItem.subMenu) {
      newItem.subMenu = map(newItem.subMenu, (subItem) => {
        if (subItem.type === "news") {
          return assign({}, subItem, { name: newLabel });
        }
        return subItem;
      });
    }
    return newItem;
  });
  return modifiedData
};


export const applyMenuAppConfig = (menuItems, config) => {
  const {
    news_menu_label,
    event_section_visibility,
  }= config;
  let modifiedData = map(menuItems, (item) => {
    let newItem = cloneDeep(item);
    if (newItem.subMenu) {
      newItem.subMenu = map(newItem.subMenu, (subItem) => {
        if (subItem.type === "news") {
          return assign({}, subItem, { name: news_menu_label });
        }
        return subItem;
      }).filter((subItem) => {
        return subItem.type !== "event" ? true : event_section_visibility;
      })
    }
    return newItem;
  })
  
  return modifiedData
};

export const addMailtoLinks = (blocks) => {
  if (!blocks || !Array.isArray(blocks)) {
    return [];
  }
  
  return blocks.map((block) => {
    if (block.type === "paragraph" && block.children) {
      block.children = block.children.map((child) => {
        if (child.type === "text" && validateEmail(child.text)) {
          return {
            ...child,
            type: "link",
            url: `mailto:${child.text}`,
            children: [{ text: child.text, type: 'text' }],
          };
        }
        return child;
      });
    }
    return block;
  });
};

export const transformBlockToText = (blocks) => {
  return addMailtoLinks(blocks)
}

const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};