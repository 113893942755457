import {getLatestContentVersion, mapImageData, mapImageFormats} from "../../../utils/mappings";
import {COMPANY, IMAGE_TYPES, NEWS} from '../../../utils/constants';

const STATE = {
    items: [],
    initialSectionId: null,
    content: null,
}

/**
 * Maps navigation children for a given payload.
 *
 * @param {object} payload - The payload containing the data to map.
 * @param {boolean} [secondary=false] - Flag indicating if it's a secondary mapping.
 * @returns {Array} - The mapped navigation children.
 */
export const mapNavChildren = (payload, secondary = false) => {
    const {data} = payload;
    return data.map(item => {
        const {id, update, attributes: {title, description: content, items}} = getLatestContentVersion(item);
        if (secondary) {
            const updateTitle = `${title}${update ? ' *' : ''}`
            const {attributes: {cover, sidebar_visibility}} = item;
            const {image} = cover ?? {image: null};
            const imageToReturn = {
                thumbnail: image && image.attributes ? mapImageData(image, IMAGE_TYPES.MEDIUM) : (
                    image && image.data && image.data.attributes ? mapImageData(image.data, IMAGE_TYPES.MEDIUM) : null
                )
            };      
            return {
                id: `${id}`,
                title: updateTitle,
                sidebar_visibility,
                image: imageToReturn
            }
        }
        return {id: `${id}`, title, content, children: mapNavChildren(items, true)};
    })
}

/**
 * Maps the content payload to a simplified format, extracting the title and elements.
 *
 * @param {Object} payload - The payload containing the content data.
 * @returns {Object|null} - The mapped content data or null if the payload is empty.
 */
const mapContent = (payload) => {
    const {data} = payload;
    if (data.length) {
        const {attributes: {title, elements}} = getLatestContentVersion(data[0]);
        return {
            title,
            elements: mapElements(elements),
        }
    }
    return null;
}

/**
 * Map elements data to a new format based on their component type.
 * @param {Array} elements - The original elements array.
 * @returns {Array} - The mapped elements array.
 */
const mapElements = (elements) => {
    return elements.map(element => {
        const {__component} = element;
        switch (__component) {
            case 'elements.rich-text':
                const {text} = element;
                return {
                    type: 'text',
                    text,
                }
            case 'elements.media-hub-relation':
                const {media_hub_items: {data: items}} = element;
                return {
                    type: 'mediahub-items',
                    items: mapMediaHubItems(items),
                }
            case 'elements.image':
                const {image} = element;

                return {
                    type: 'image',
                    ...mapImageFormats(image),
                }
            case 'elements.video':
                const {
                    coverImage: {data: {attributes: {url: coverUrl}}},
                    videoFile: {data: {attributes: {url: videoUrl, caption: videoCaption}}}
                } = element;
                return {
                    type: 'video',
                    url: videoUrl,
                    cover: coverUrl,
                    caption: videoCaption,
                }
            case 'elements.external-link':
                const {title, url} = element;
                return {
                    type: 'link',
                    title,
                    url,
                }
            case 'elements.pdf':
                const {title: pdfTitle, pdfFile: {data: {attributes: {size, url: pdfUrl}}}} = element;
                return {
                    type: 'pdf',
                    title: pdfTitle,
                    url: pdfUrl,
                    size: (size / 1000).toFixed(2),
                }
            default:
                return null;
        }
    });
}

/**
 * Maps an array of Media Hub items and extracts necessary information from each item.
 *
 * @param {Array} items - The array of Media Hub items.
 * @return {Array} - An array of mapped items containing extracted information.
 */
const mapMediaHubItems = (items) => {
    return items.map(item => {
        const {id, update, attributes: {title, locked, image}} = getLatestContentVersion(item);
        return {
            title: `${title}${update ? ' *' : ''}`,
            locked,
            image: mapImageFormats(image),
            id: `${id}`,
            url: `${id}`,
        }
    });
}

/**
 * Transforms the payload for Load Nav Success case
 *
 * @param {State} state - The existing state
 * @param {any} payload - The payload from the action
 * @returns {State} The new state
 */
function executeLoadNavSuccess(state, payload) {
    const transformed = mapNavChildren(payload);

    if (transformed.length) {
        const {id: initialSectionId} = transformed[0];
        return {...state, items: transformed, initialSectionId};
    }

    // Returns existing state when transformed has no length
    return state;
}

/**
 * Transforms the payload for Load Content Success case
 *
 * @param {State} state - The existing state
 * @param {any} payload - The payload from the action
 * @returns {{stack: Array<ConstructName>, bulletLastUsed: string | undefined, indexStack: Array<number>, handle: Handle, bulletCurrent: string | undefined, unsafe: Array<Unsafe>, content: (Object|null), handlers: Handlers, safe: Safe, options: Options, containerFlow: ContainerFlow, containerPhrasing: ContainerPhrasing, createTracker: CreateTracker, enter: Enter, join: Array<Join>, indentLines: IndentLines, associationId: AssociationId}} The new state
 */
function executeLoadContentSuccess(state, payload) {
    return {...state, content: mapContent(payload)};
}

/**
 * Updates the state based on the provided action.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action containing type and payload.
 * @returns {Object} The updated state after processing the action.
 */
function news(state = STATE, action) {
    const {type, payload} = action;

    switch (type) {
        case NEWS.LOAD_NAV:
            return {...state, items: [], initialSectionId: null};

        case NEWS.LOAD_NAV_SUCCESS:
            return executeLoadNavSuccess(state, payload);

        case NEWS.LOAD_CONTENT_SUCCESS:
            return executeLoadContentSuccess(state, payload);

        default:
            return state;
    }
}

export default news;
