import React from "react";
import ReactDOM from "react-dom/client";
import Modal from "react-modal";
import {Provider} from "react-redux";
import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider,} from "react-router-dom";
import {ThemeProvider} from "styled-components";

import App from "./App";
import FooterLayout from "./container/footer-layout";
import HeaderLayout from "./container/header-layout";
import "./fa.css";
import "./index.css";
import store from "./store";
import CompanyLanding from "./views/company-landing";
import Connect from "./views/connect";
import EmailConfirmation from "./views/email-confirmation";
import Events from "./views/events";
import Lobby from "./views/lobby";
import MediaHub from "./views/media-hub";
import News from "./views/news/News";
import PasswordRecovery from "./views/password-recovery";
import Register from "./views/register";
import ResetPassword from "./views/reset-password";
import SharedLayoutGLS from "./views/gls/SharedLayoutGLS";
import GLSXCELLENCE from "./views/gls/GLSXelence";
import Dutube from "./views/gls/Dutube";
import GLSEvents from "./views/gls/Events";
import GLSMediaHub from "./views/gls/MediaHub";
import GLSInnerHub from "./views/gls/InnerHub";
import GLSLogin from "./views/gls/Login";
import SearchResult from "./views/search-result/SearchResult";
import { IN_A_BOX, MEDIA_HUB } from "./utils/constants/gls-hub-content";
import { SnackbarProvider } from 'notistack';
import PageNotFound from './NotFound'

const theme = {
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
    },
};

Modal.setAppElement("#root");

function MultiRoute(el, ...paths) {
    return paths.map((p) => <Route key={p} element={el} path={p}/>);
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<App/>} errorElement={<PageNotFound />}>
           
           <Route exact path="/connect" element={<Connect/>}/>
           <Route path="/not-found" element={<PageNotFound />}/>
           <Route exact path="/register" element={<Register/>}/>
            <Route exact path="/forgot-password" element={<PasswordRecovery/>}/>
            <Route exact path="/reset-password" element={<ResetPassword/>}/>
            <Route exact path="/email-confirmation" element={<EmailConfirmation/>}/>

            {/* Redirections */}
            <Route path="/lobbygls" element={<Navigate to="/" replace />} />
            <Route path="/selection" element={<Navigate to="/" replace />} />

            {/*Headerlayout checks if :companyName is part of the available companies and redirects to / if not found.*/}
            <Route exact path="/" element={<HeaderLayout/>} >
                <Route exact index path="/" element={<Lobby/>}/>
                <Route exact path="/:companyName/search-result" element={<SearchResult/>} />
                <Route path="/gls" element={<SharedLayoutGLS/>}>
                    <Route index element={<Navigate to="/gls/lobby" replace/>}/>
                    <Route exact path="lobby"/>
                    <Route exact path="login" element={<GLSLogin/>}/>
                    <Route exact path="glsxcellence" element={<GLSXCELLENCE/>}/>
                    <Route exact path="events" element={<GLSEvents/>}/>
                    <Route path="mediahub" element={<GLSMediaHub selectedHub={MEDIA_HUB}/>}/>
                    <Route path="mediahub/:section" element={<GLSMediaHub selectedHub={MEDIA_HUB}/>}/>
                    <Route path="in-a-box" element={<GLSMediaHub selectedHub={IN_A_BOX}/>}/>
                    <Route path="in-a-box/:section" element={<GLSMediaHub selectedHub={IN_A_BOX}/>}/>
                    <Route path="in-a-box/:section/:item" element={<GLSInnerHub selectedHub={IN_A_BOX}/>}/>
                    <Route path="in-a-box/:section/:item/:innerSection" element={<GLSInnerHub selectedHub={IN_A_BOX}/>}/>
                    <Route exact path="dutube" element={<Dutube/>}/>
                </Route>
                <Route exact path="/:companyName" element={<CompanyLanding/>}/>
                <Route path="/:companyName" element={<FooterLayout/>}>
                    <Route path="news" element={<News/>}>
                        <Route index element={<News/>}/>
                        <Route path=":sectionId?/:itemId?/:mediaId?" element={<News/>}/>
                    </Route>
                    <Route path="mediahub" element={<MediaHub/>}>
                        <Route index element={<MediaHub/>}/>
                        <Route
                            path=":mediaHubId?/:sectionId?/:itemId?/:mediaId?"
                            element={<MediaHub/>}
                        />
                    </Route>
                    <Route path="events" element={<Events/>}>
                        <Route index element={<Events/>}/>
                    </Route>
                </Route>
            </Route>
        </Route>
    )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <SnackbarProvider 
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <ThemeProvider theme={theme}>
                <RouterProvider router={router}/>
            </ThemeProvider>
        </SnackbarProvider>
    </Provider>
);
