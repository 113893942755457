import {USER} from '../../../utils/constants';

const STATE = {
    id: "",
    jwt: "",
    login: {
        loading: false,
        success: false,
        error: null,
    },
    registration: {
        loading: false,
        success: false,
        error: null,
    },
    recovery: {
        success: false,
        error: null,
        loading: false
    },
    reset: {
        success: false,
        error: null,
        loading: false
    },
    user: {},
    profile: {},
    companies: [],
    functions: [],
    languages: [],
    therapeuticAreas: [],
    changePassword: {
        success: null,
        error: null,
        isUpdating: false,
    },
    updateProfile: {
        success: null,
        error: null,
    },
    uploadUserAvatar: {
        success: null,
        isUploading: false,
        error: null,
    }
}

const mapCompanies = (companies) => {
    const {data} = companies;
    return data.map(company => {
        const {id, attributes: {name}} = company;
        return {id, name};
    });
}

const mapFunctions = (functions) => {
    const {data} = functions;
    return data.map(func => {
        const {id, attributes: {title}} = func;
        return {id, title};
    });
}

const mapLanguages = (languages) => {
    const {data} = languages;
    return data.map(language => {
        const {id, attributes: {title}} = language;
        return {id, title};
    });
}

const mapTherapeuticAreas = (therapeutic_areas) => {
    const {data} = therapeutic_areas;
    return data.map(area => {
        const {id, attributes: {title}} = area;
        return {id, title};
    });
}

function user(state = STATE, action) {
    const {type, payload} = action;
    const {id, jwt, error, profile} = payload || {};
    switch (type) {
        case USER.RESET_LOGIN_USER:
            return {...state, login: {success: false, error: null}, id: "", jwt: ""};
        case USER.LOGIN:
            return {...state, login: {success: false, error: null, loading: true}, id: "", jwt: ""};
        case USER.LOGIN_SUCCESS:
            return {...state, login: {success: true, error: null, loading: false}, id, jwt, profile: profile || {}};
        case USER.LOGIN_ERROR:
            return {...state, login: {success: false, error, loading: false}, id: "", jwt: ""};
        case USER.RESET_REGISTRATION:
            return {...state, registration: {success: false, error: null, loading: false}};
        case USER.REGISTER:
            return {...state, registration: {success: false, error: null, loading: true}};
        case USER.REGISTER_SUCCESS:
            return {...state, registration: {success: true, error: null, loading: false}};
        case USER.REGISTER_ERROR:
            return {...state, registration: {success: false, error, loading: false}};
        case USER.LOAD_USER_PROFILE_SUCCESS:
            return {...state, user: payload?.user, profile};
        case USER.UPDATE_USER_PASSWORD:
            return {...state, changePassword: {success: null, error: null, isUpdating: true}};
        case USER.UPDATE_USER_PASSWORD_SUCCESS:
            return {...state, changePassword: {success: true, error: null, isUpdating: false}};
        case USER.UPDATE_USER_PASSWORD_ERROR:
            return {...state, changePassword: {success: false, error, isUpdating: false}};
        case USER.UPDATE_USER_PROFILE:
            return {...state, profile: {...state.profile, ...payload}, updateProfile: {success: false, error: null}};
        case USER.UPDATE_USER_PROFILE_SUCCESS:
            return {...state, updateProfile: {success: true, error: null}};
        case USER.UPDATE_USER_PROFILE_ERROR:
            return {...state, updateProfile: {success: false, error}};
        case USER.RESET_RECOVER_PASSWORD:
            return {...state, recovery: {success: false, error: null, loading: false}};
        case USER.RECOVER_PASSWORD:
            return {...state, recovery: {success: false, error: null, loading: true }};
        case USER.RECOVER_PASSWORD_SUCCESS:
            return {...state, recovery: {success: true, error: null, loading: false }};
        case USER.RECOVER_PASSWORD_ERROR:
            return {...state, recovery: {success: false, error, loading: false }};
        case USER.RESET_PASSWORD:
            return {...state, reset: {success: false, error: null, loading: true}};
        case USER.RESET_PASSWORD_SUCCESS:
            return {...state, reset: {success: true, error: null, loading: false}};
        case USER.RESET_PASSWORD_ERROR:
            return {...state, reset: {success: false, error, loading: false}};
        case USER.LOAD_USER_COMPANIES_SUCCESS:
            const companies = mapCompanies(payload);
            return {...state, companies};
        case USER.LOAD_USER_COMPANIES:
        case USER.LOAD_USER_COMPANIES_ERROR:
            return {...state, companies: []};
        case USER.LOAD_USER_FUNCTIONS_SUCCESS:
            const functions = mapFunctions(payload);
            return {...state, functions};
        case USER.LOAD_USER_FUNCTIONS:
        case USER.LOAD_USER_FUNCTIONS_ERROR:
            return {...state, functions: []};
        case USER.LOAD_USER_LANGUAGES_SUCCESS:
            const languages = mapLanguages(payload);
            return {...state, languages};
        case USER.LOAD_USER_LANGUAGES:
        case USER.LOAD_USER_LANGUAGES_ERROR:
            return {...state, languages: []};
        case USER.LOAD_USER_THERAPEUTIC_AREAS_SUCCESS:
            const therapeuticAreas = mapTherapeuticAreas(payload);
            return {...state, therapeuticAreas};
        case USER.LOAD_USER_THERAPEUTIC_AREAS:
        case USER.LOAD_USER_THERAPEUTIC_AREAS_ERROR:
            return {...state, therapeuticAreas: []};
        case USER.UPLOAD_USER_AVATAR:
            return {...state, uploadUserAvatar: {success: null, error: null, isUploading: true}};
        case USER.UPLOAD_USER_AVATAR_SUCCESS:
            return {...state, uploadUserAvatar: {success: true, error: null, isUploading: false}};
        case USER.UPLOAD_USER_AVATAR_ERROR:
            return {...state, uploadUserAvatar: {success: false, error, isUploading: false}};
        case USER.LOGOUT:
            return {
                ...state,
                login: {success: false, error: null},
                firstname: "",
                lastname: "",
                jwt: "",
                profile: {},
                user: {}
            };
        default:
            return state;
    }
}

export default user;
